import React, { useCallback, useMemo, useState } from 'react';
import DownloadQR from '../../assets/images/icons/other/download-app-qr.inline.svg';
import useWindow from '../../hooks/useWindow';
import Badges from '../Badges';
import { SecondaryDescription, SecondaryTitle } from '../Typography';

const downloadAppLink = 'https://rb.gy/xxgbck';

function DownloadBonusModal() {
  const { isMobile } = useWindow();
  const [showTooltip, setShowTooltip] = useState(false);

  const onCopyLink = useCallback(() => {
    navigator.clipboard.writeText(downloadAppLink);

    setShowTooltip(true);

    setTimeout(() => {
      setShowTooltip(false);
    }, 1500);
  }, []);

  const linkRightClassName = useMemo(() => {
    const basicClassName = 'modal__download-bonus__link-left';

    return showTooltip ? `${basicClassName} ${basicClassName}-copied` : basicClassName;
  }, [showTooltip]);

  return (
    <div className="modal__download-bonus">
      <div className="modal__download-bonus-text">
        <SecondaryTitle>Fancy saving more money on</SecondaryTitle>
        <SecondaryTitle>
          <span>your mortgage</span>
          ?
        </SecondaryTitle>
        <SecondaryDescription>
          Download Sprive app, become mortgage <br /> free faster and save thousands.
        </SecondaryDescription>
      </div>
      <div className="modal__download-bonus__image-container">
        {!isMobile ? <DownloadQR /> : <Badges />}
      </div>
      {isMobile ? (
        <>
          <div className="modal__download-bonus__separator-container">
            <span />
            <p>Or</p>
            <span />
          </div>
          <div className="modal__download-bonus__link-container">
            <div className="modal__download-bonus__link-right">
              <p>{downloadAppLink}</p>
            </div>
            <div className={linkRightClassName} onClick={onCopyLink}>
              <span className="modal__download-bonus__link-tooltip">Copied</span>
              <p>Copy</p>
            </div>
          </div>
        </>
      ) : (
        <SecondaryDescription>Scan the QR code to download Sprive</SecondaryDescription>
      )}
      {!isMobile && 
        <div className="modal__download-bonus-badges">
            <Badges />
        </div>
      }
    </div>
  );
}

export default DownloadBonusModal;
